<template>
  <div>
    <div class="head">
      <h2
        class="text-center top-text "
        :class="$vuetify.breakpoint.xs ? 'py-10' : 'py-16'"
      >
        Medical Consultant Speaks On The Role of Storage In Managing Diabetes.
      </h2>
    </div>
    <v-row
      class="container-top"
      :class="$vuetify.breakpoint.xs ? 'pa-4' : 'pa-16'"
    >
      <v-col cols="12" sm="7" :class="$vuetify.breakpoint.xs ? '' : 'pr-12'">
        <figure class="">
          <img
            src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1634722714/world-diabetes-day-doctor-holding-patient-hand-s_wccavq.jpg"
            alt=""
            width="100%"
            style="max-height: 32rem;width: 100%;object-fit: contain"
          />
        </figure>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="body-1" style="color:#28A84A; font-weight:500;">
            Author: Gricd
          </p>
        </div>
        <div class="bottom-part">
          <h1 class="pb-8 heading">
            Medical Consultant Speaks On The Role of Storage In Managing
            Diabetes.
          </h1>
          <div
            class="date d-flex justify-space-between py-7"
            style="flex-flow: row wrap;"
          >
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (7).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 13px;margin-top: 0.33rem;"
              />
              <p class="body-1 font-weight-bold">50 views</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (8).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">0 comments</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/calendar_today.png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">Date: Oct 15, 2021</p>
            </span>
          </div>
          <p>
            Diabetes (or diabetes mellitus), a chronic disease, is one of the
            leading causes of death in Nigeria. One in seventeen adult Nigerians
            is predicted to be living with the disease - that is about 11.2
            million people in Nigeria. This illness is a result of the body’s
            inability to properly process and use glucose from the food one
            eats. Diabetes occurs when the pancreas fails to create enough
            insulin, or when the body fails to properly utilize the insulin it
            does produce (insulin is a hormone that regulates blood sugar
            levels). Diabetes claimed the lives of 1.5 million people around the
            world in 2019.
          </p>
          <p>
            In an interview with Carriers of Life, Dr Ogunwale, a renowned
            medical practitioner, talked about diabetes, its impact on the
            wellbeing of the Nigerian population, and the problem of storage in
            preserving the potency of drugs used in the treatment of diabetes.
          </p>
          <p class="grey-text font-italics">
            "Diabetes is a metabolic disease that occurs when the body has
            problems handling food like carbohydrates, fat and protein. This is
            because there is a problem in the production of insulin. Insulin is
            a chemical substance the body uses to regulate the amount of glucose
            in the blood." - Dr Ogunwale
          </p>
          <p>
            Dr Ogunwale also highlighted some data showing the prevalence of
            diabetes in the world. According to the medical professional,
          </p>
          <p class="grey-text font-italics">
            "Currently, there are 450 million people affected by diabetes
            worldwide. In Africa, we have over 20 million adults affected with
            diabetes. Sadly, we predict that in less than 30 years that number
            in the world and Africa will have risen to 700 million and 50
            million respectively. There are 4 million deaths from diabetes every
            year. More women die from diabetes than men every year".
          </p>
          <p>
            Generally, there are various types of diabetes, but diabetes
            mellitus is the most common one and it is primarily categorised into
            two types(Type 1 and Type 2) Type 1 diabetes is a rare type of
            diabetes mellitus and it is otherwise known as Insulin Dependent
            Diabetes Mellitus (IDDM). Type 1 diabetes occurs when the immune
            system attacks and kills the insulin-producing cells in the
            pancreas. It was originally known as juvenile diabetes since it was
            more commonly diagnosed in children and teens. It accounts for about
            5% of patients with diabetes. People affected by type 1 diabetes
            must take insulin injections for the rest of their lives to survive.
          </p>
          <div>
            <figure class="pt-8 pb-4 d-flex justify-center">
              <img
                src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1634721709/world-diabetes-day-close-up-picture-using-medical-device-check-glucose-blood_ofva5d.jpg"
                alt=""
                width="100%"
                class=""
                style="max-width:27rem"
              />
            </figure>
          </div>
          <p>
            Type 2 Diabetes is the most common type of diabetes there is and it
            accounts for over 90percent of patients with diabetes with over 400
            million cases reported worldwide and about 11.2 million cases in
            Nigeria. This type of diabetes occurs mainly as a result of
            lifestyle choices amongst other factors, it is mostly as a result of
            high blood glucose.
          </p>
          <p>
            Insulin shots are key in helping diabetes patients stay alive, it’s
            the primary medication available for the treatment of the disease
            and to avoid complications, it is essential to ensure the insulin
            shots being administered to them are in potent condition.
          </p>
          <p class="grey-text font-italics">
            "There are two types of insulin: those that come in bottles and
            those that come in the form of a pen. Those stored in bottles are
            quite difficult to preserve hence the critical need for an
            appropriate temperature-controlled environment for the storage of
            these bottled insulins. The bottled insulin needs to be stored at
            2-8 degrees, they require appropriate storage so diabetes patients
            don't get treated with medication that has lost its potency
            already," - Dr Ogunwale.
          </p>
          <p>
            Because of economic conditions, bottle insulins are more common in
            Nigeria as they cost way less to purchase, however, the Nigerian
            weather still poses a major threat in maintaining the efficacy of
            both types of insulin. The pen insulins can be kept at room
            temperature once opened but, on most days in the country, the
            weather is as high as 29 degrees which is not favourable, especially
            when the insulins haven’t been opened.
          </p>
          <div>
            <figure class="pt-8 pb-4 d-flex justify-center">
              <img
                src="https://res.cloudinary.com/dcjbgkkej/image/upload/v1634721623/man-preparing-insulin-diabetic-syringe-injection_bbgu5g.jpg"
                alt=""
                width="100%"
                class=""
                style="max-width:27rem"
              />
            </figure>
          </div>
          <p>
            Nigeria lacks a robust cold storage infrastructure, storing insulin
            might be a difficult task at times and temperature fluctuations in
            insulin storage facilities can result in a decrease in the chemical
            substance's efficacy. Dr Ogunwale emphasized the importance of using
            adequate cold chain equipment to store and monitor stored insulin to
            retain its potency and limit wastage. He urged hospitals and
            pharmacies to employ efficient cold chain storage solutions to
            ensure the proper preservation of insulin shots.
          </p>
          <p>
            By 2030, it is expected that global insulin consumption will have
            increased. Health facilities in Nigeria must adopt the use of proper
            cold chain facilities to prepare for such a time when they'll be an
            increased demand for insulin.
          </p>
          <p>
            Dr Ogunwale concluded by giving tips on healthy habits to incur to
            prevent and manage diabetes insipidus. He said, "Carbonated drinks
            and processed food contain high amounts of highly refined sugar. It
            is best if we consume less of these products to avoid getting high
            sugar levels. Also, exercise is very important to prevent and manage
            diabetes".
          </p>
        </div>
      </v-col>
      <v-col cols="12" sm="5">
        <div class="search" style="padding: 2rem 2rem;">
          <input type="text" placeholder="Search" />
          <img :src="mySVG" class="search-icon" />
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">Recent Posts</h2>
          </div>
          <div class="right-sub mb-6" v-for="(m, i) in rightSide" :key="i">
            <v-row>
              <v-col cols="5">
                <img :src="m.img" alt="" width="100%" />
              </v-col>
              <v-col cols="7 pa-4">
                <span class="d-flex py-3">
                  <img
                    src="../../assets/icons/calendar_today.png"
                    alt=""
                    class="mr-3"
                    style="    width: 20px;height: 20px;"
                  />
                  <p class="caption">
                    {{ m.date }}
                  </p>
                </span>
                <p class="subtitle">
                  <router-link :to="m.link">
                    {{ m.content }}
                  </router-link>
                </p>
              </v-col>
            </v-row>
            <hr class="mt-5" v-show="i !== 2" />
          </div>
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">
              Our Categories
            </h2>
          </div>
          <div
            class="right-news mb-6 d-flex"
            v-for="(m, i) in info"
            :key="i"
            style="height:5rem"
          >
            <div
              style="background: rgba(242, 245, 247, 0.6); width:85%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 pl-5">
                {{ m }}
              </p>
            </div>
            <div
              style="background: #28A84A; width:15%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 mx-auto white--text">0{{ i + 1 }}</p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="popular mb-10">
      <div class="section-head">
        <div class="heading-title">
          <h2 style="font-size:34px">
            More amazing articles for you
          </h2>
        </div>
      </div>
      <v-row
        class="justify-space-between"
        :style="
          $vuetify.breakpoint.xs
            ? 'padding:0 1rem; justify-content:space-evenly'
            : 'padding:0 3rem; justify-content:space-evenly'
        "
      >
        <div v-for="(m, i) in lastCard" :key="i" class="mx-auto">
          <v-hover v-slot="{ hover }">
            <v-card
              class=" mb-10 card-cont"
              max-width="400"
              outlined
              :style="
                hover
                  ? 'box-shadow: 0px 20px 50px 0px rgba(84, 143, 188, 0.1);'
                  : ''
              "
            >
              <v-img :src="m.img" height="300px"> </v-img>
              <v-card-title>
                {{ m.content }}
              </v-card-title>

              <v-card-actions>
                <v-btn
                  text
                  :style="hover ? 'color:#28A84A' : ''"
                  :to="m.link"
                  style="font-size:10px"
                >
                  Read More
                </v-btn>
                <v-spacer></v-spacer>
                <p class="pr-3 date">10 March, 2021</p>
              </v-card-actions>
            </v-card>
          </v-hover>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
// import img1 from '../../assets/images/Rectangle 25.png';
// import img2 from '../../assets/images/Rectangle 26.png';
// import img3 from '../../assets/images/image 11 (1).png';
import image4 from "../../assets/blog new/eyecarengonline_1628779252293258.jpg";
// import image5 from "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg";
import image6 from "../../assets/blog new/eyecarengonline_1628779252293258.jpg";
import image7 from "../../assets/blog new/190624-culturally-competent-care-3-khn-ew-349p.jpg";
// import image5 from "../../assets/blog new/190624-culturally-competent-care-3-khn-ew-349p.jpg";
import image8 from "../../assets/blog new/pharmaceuticall.png";
// import image from '../../assets/images/Rectangle 27.png';
export default {
  data: () => ({
    loaded: false,
    loading: true,
    info: ["News", "Health", "Logistics"],
    rightSide: [
      {
        img: image7,
        content:
          "Digitalization in the African retail pharmaceutical sector (With Shelflife by Field intelligence and mPharma)",
        date: "Sept 19, 2021",
        link:
          "/blog/digitalisation-in-the-retail-pharmaceutical-sector-shelf-life-director-of-pharmacy-suleman-Sule-and-mPharmas-joyce-aderinlewo-talked-about-how-they-are-helping-thousands-of-community-pharmacies-use-data-and-technology-to-optimise-operations",
      },
      {
        img:
          "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg",
        content:
          "Gricd tracks 4.2millions doses of Moderna Vaccines for the Nigerian Government",
        date: "Sept 12, 2021",
        link:
          "/blog/gricd-tracks-4.2millions-doses-of-moderna-vaccines-for-the-nigerian-government",
      },
      {
        img: image8,
        content:
          "Gricd works with ISN to assure product integrity and avert waste risk.",
        date: "Sept 18, 2021",
        link:
          "/blog/gricd-works-with-isn-to-assure-product-integrity-and-avert-waste-risk",
      },
    ],
    lastCard: [
      {
        img: image4,
        content:
          "INTERVIEW: Managing Director Of Kesona Eye Centre Speaks About Glaucoma And Its Impact On The Health Of Nigerians",
        date: "July 19, 2021",
        link:
          "/blog/interview-managing-director-of-kesona-eye-centre-speaks-about-glaucoma-and-its-impact-on-the-health-of-nigerians",
      },
      {
        img:
          "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg",
        content:
          " Gricd tracks 4.2millions doses of Moderna Vaccines for the Nigerian Government",
        date: "June 13, 2021",
        link:
          "/blog/gricd-tracks-4.2millions-doses-of-moderna-vaccines-for-the-nigerian-government",
      },
      {
        img: image6,
        content:
          " Dr Rose Azuike Speaks About Glaucoma and Its Effects on the lives of Nigerians",
        date: "June 13, 2021",
        link:
          "/blog/dr-rose-azuike-speaks-about-glaucoma-and-its-effects-on-the-lives-of-nigerians",
      },
    ],
    mySVG: require("../../assets/Logo/search.svg"),
  }),
  metaInfo() {
    return {
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://gricd.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Blog",
                item: "https://gricd.com/blog",
              },
              {
                "@type": "ListItem",
                position: 3,
                name:
                  "Medical Consultant Speaks On The Role of Storage In Managing Diabetes.",
                item:
                  "https://gricd.com/blog/medical-consultant-speaks-on-the-role-of-storage-in-managing-diabetes",
              },
            ],
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.head {
  background: rgba(40, 168, 74, 1);
  color: white;
  padding: 1rem 0;
  @media (max-width: 1024px) {
    // padding: 3rem 0;
  }
}
a {
  text-decoration: none !important;
  color: black !important;
}
.heading {
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 140%;
  color: #212529;
}
p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;
  color: #212529;
}
.subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 134.7%;
  letter-spacing: 0.5px;
  color: #212529;
}
.search {
  padding: 1rem 2rem;
  background: rgba(242, 245, 247, 0.7);
  margin-bottom: 2rem;
  position: relative;
  p {
    background: white;
    margin: 0;
    padding: 1.5rem 1rem;
  }
}
.right-sub {
  .caption {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
.right-news {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
// p span {
//   text-decoration: underline;
//   color: #28a84a;
// }
.green-cont {
  width: 30%;
  border: solid #28a84a;
  background: #28a84a;
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.green-cont h2 {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}
.card-sub {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #212529;
}
.card-cont {
  background: #ffffff;
  border-radius: 5px !important;
  width: 24rem;
  @media (max-width: 767px) {
    width: 22rem !important;
  }
  @media (max-width: 1290px) and (min-width: 770px) {
    width: 23rem;
    // min-height: 34rem;
  }
}
.card2 {
  padding: 0 6rem 4rem;
}
.section-head {
  height: 15rem;
  padding: 5rem 5rem;
}
.v-card__title {
  @media (max-width: 1290px) and (min-width: 770px) {
    font-size: 15px !important;
  }
}
button.v-btn.v-btn--text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  /* or 24px */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #292929;
}
.date {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  letter-spacing: 0.005em;
  color: #748494;
  margin: 0;
  @media (min-width: 768px) {
    padding-right: 10rem;
  }
}
.date {
  p {
    font-size: 0.775rem !important;
  }
}
.v-card__title {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140.7%;
  letter-spacing: 0.5px;
  color: #292929;
  word-break: inherit;
}

.date {
  p {
    font-size: 0.775rem !important;
  }
}

@media (max-width: 1290px) and (min-width: 770px) {
  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 140%;
    color: #212529;
    padding-bottom: initial !important;
  }

  .section-head {
    height: 7rem;
    padding: 0rem 5rem;
  }
  .right-news p {
    font-weight: 600;
  }

  .date {
    font-size: 12px;
  }
  button.v-btn.v-btn--text {
    font-size: 10px;
  }
  .subtitle {
    font-size: 15px;
  }
  h2 {
    font-size: 1.7rem !important;
  }
}
.grey-text {
  color: #676464;
}
</style>
